import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import Privacy from "./components/Privacy";
import store from "./store";
import { aws, log } from "./core";

// window.LOG_LEVEL = "VERBOSE";


// margin: 0;
// font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//   sans-serif;
// -webkit-font-smoothing: antialiased;
// -moz-osx-font-smoothing: grayscale;


// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }







// const useStyles = makeStyles(theme => ({
//   "@global": {
//     html: {
//       height: "100%",
//     },
//     body: {
//       height: "100%",
//       margin: 0,
//       fontFamily: "-apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif",
//       lineHeight: 1.5,
//       fontSize: 16,
//     },
//     "#root": {
//       height: "100%",
//     },
//     "h1, h2, h3, h4, h5, h6": {
//       margin: 0,
//       fontWeight: 500,
//     },
//     "h1": {
//       fontSize: 36,
//       lineHeight: "54px",
//       color: "black",
//     },
//     "h2": {
//       fontSize: 24,
//       lineHeight: "36px",
//       color: "black",
//     },
//   }
// }));

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff5c9d",
      main: "#ff006f",
      dark: "#c50045",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
        }
        button.MuiButton-root {
          min-width: 48px;
        }
      `,
    },
    // MuiButtonBase: {
    //   defaultProps: {
    //     disableRipple: true,
    //   },
    // },
  },
});

aws.initialize();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // https://beta.reactjs.org/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
  /*<React.StrictMode>*/
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<App />} />
            <Route path={"/privacy"} element={<Privacy />} />
            <Route path={":username"} element={<App />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
