import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const Students = ({ user, courseMap, studentMap, pointsEntries }) => {
  const pointsByDate = [];

  const startDate = new Date("2022-09-06T00:00:00Z");
  const endDate = new Date("2022-10-20T00:00:00Z");
  const now = new Date();
  const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  const numberOfClasses = [0, 8, 4, 3, 4, 4, 0]; // Sunday - Saturday, maps to getDay()

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const day = d.getUTCDay();

    if (d.getTime() <= today.getTime()) {
      const points = {};

      Object.values(studentMap).forEach(student => {
        points[student.studentId] = numberOfClasses[day] * 3;
      });

      pointsEntries.forEach(pointsEntry => {
        const pointsEntryDate = new Date(pointsEntry.dateCreated);
        const pointsEntryDateUTC = new Date(Date.UTC(pointsEntryDate.getFullYear(), pointsEntryDate.getMonth(), pointsEntryDate.getDate()));
        if (pointsEntryDateUTC.getTime() == d.getTime()) {
          points[pointsEntry.studentId] += pointsEntry.points;
        }
      });

      pointsByDate.push({
        date: new Date(d),
        points: points,
        field: d.toISOString(),
        headerName: d.toLocaleDateString("en", {timeZone:"UTC"}),
      });
    }
  }

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 140,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 140,
    },
    {
      field: "totalPoints",
      headerName: "Total Points",
      type: "number",
      width: 140,
    }
  ];

  pointsByDate.forEach(points => {
    columns.push({
      field: points.field,
      headerName: points.headerName,
      type: "number",
      width: 140,
    });
  });

  const rows = Object.values(studentMap).map(student => {
    const id = student.studentId;
    const firstName = student.firstName;
    const lastName = student.lastName;

    const rows = { id, firstName, lastName };

    let totalPoints = 0;

    pointsByDate.forEach(points => {
      rows[points.field] = points.points[student.studentId];
      totalPoints += points.points[student.studentId];
    });

    rows.totalPoints = totalPoints;

    return rows;
  });

  return (
    <TableContainer sx={{ mt: 2 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.field}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {columns.map(column => (
                <TableCell key={column.field}>{row[column.field]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Students;