import API from "@aws-amplify/api";
import config from "../config";
import auth from "./auth";
import log from "./log";

const api = {};

api.addPointsEntry = async pointsEntry => {
  return await invokeApi("add-points-entry", { pointsEntry }, true);
};

api.getAuthLink = async () => {
  return await invokeApi("get-auth-link");
};

api.getData = async () => {
  return await invokeApi("get-data", {}, true);
};

api.getToken = async code => {
  return await invokeApi("get-token", { code });
};

api.refreshData = async () => {
  return await invokeApi("refresh-data", {}, true);
};

api.removePointsEntry = async pointsEntryId => {
  return await invokeApi("remove-points-entry", { pointsEntryId }, true);
};

const invokeApi = async (endpoint, body = {}, authRequired = false) => {
  // if (authRequired) {
  //   if (!auth.isAuthenticated()) {
  //     throw new Error("User must be authenticated to call this API.");
  //   }
  // }

  log.debug(`Calling ${endpoint} endpoint...`, body);
  try {
    const result = await API.post("api", "/" + endpoint, { body }) || {};
    log.debug(result);
    return result;
  } catch (error) {
    log.error(error);
    return null;
  }
};

export default api;