import React, { useCallback, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import update from "immutability-helper";
import { api } from "../core";

const Overview = ({ user }) => {
  return (
    <Box sx={{ pt: 2 }}>
      <h2>How does Party Points work?</h2>
      <p>Students will earn 3 party points per class per day by default if they meet the expectations below — meaning there is no need to make a points entry. However, if a student does not follow one or more of the expectations, teachers may deduct points. Or, if a student goes above and beyond, teachers may reward additional points.</p>
      <p>Points will accumulate for each student until it’s time for the party. Students will need 80% of the total possible points to participate in the party.</p>
      <h2>Expectations</h2>
      <ol>
        <li>Attendance — Comes on time and stays for full period (no long breaks)</li>
        <li>Engagement — Participates in class and attempts the classwork</li>
        <li>Community Member — Is respectful, responsible and safe (no bullying, inappropriate language, touching, throwing objects, disregarding teacher redirection, etc.)</li>
      </ol>
      <h2>Absences</h2>
      <p>If a student is absent from a class, they will receive the default 3 points. However, if a student skips class (unexcused absence), the teacher should subtract 3 points for that class.</p>
    </Box>
  );
};

export default Overview;