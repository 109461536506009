import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const PPAutocomplete = React.forwardRef(({ id, autoFocus, label, error, helperText, onChange, ...props }, ref) => {           
  return (
    <Autocomplete
      fullWidth
      autoHighlight
      id={id}
      onChange={(event, value) => {
        const fakeEvent = {
          target: {
            id: id,
            value: value
          }
        };
        onChange(fakeEvent);
      }}
      renderInput={params => (
        <TextField
          id={id}
          inputRef={ref}
          // name={id}
          autoFocus={autoFocus}
          label={label}
          margin="normal"
          error={error}
          helperText={helperText}
          {...params}
        />
      )}
      {...props}
    />
  );
});

export default PPAutocomplete;