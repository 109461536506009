import React, { useState, useRef } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import PPAutocomplete from "./PPAutocomplete";
import { api, util } from "../core";

const validationSchema = yup.object({
  course: yup
    .mixed()
    .required("Required"),
  student: yup
    .mixed()
    .required("Required"),
  points: yup
    .mixed()
    .required("Required")
});

const AddPointsDialog = ({ existingPointsEntry, user, courseMap, studentMap, open, handleClose, handleOpenAlert }) => {
  const [students, setStudents] = useState([]);
  const studentRef = useRef();

  const courses = Object.values(courseMap).filter(course => course.teacherId === user.userId).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (b.name > a.name) {
      return 1;
    } else {
      return 0;
    }
  });

  const handleCancel = () => {
    formik.resetForm();
    // formik.setTouched({}, false);
    handleClose(null);
  };

  const formik = useFormik({
    initialValues: {
      course: existingPointsEntry ? courseMap[existingPointsEntry.courseId] : null,
      student: existingPointsEntry ? studentMap[existingPointsEntry.studentId] : null,
      points: existingPointsEntry ? existingPointsEntry.points : null,
      description: existingPointsEntry ? existingPointsEntry.description : "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const pointsEntry = {
        pointsEntryId: existingPointsEntry ? existingPointsEntry.pointsEntryId : util.makeId(),
        dateCreated: existingPointsEntry ? existingPointsEntry.dateCreated : Date.now(),
        partyId: user.partyId,
        teacherId: user.userId,
        courseId: values.course.courseId,
        studentId: values.student.studentId,
        points: values.points,
        description: values.description
      }
      await api.addPointsEntry(pointsEntry);

      // resetForm();
      formik.setFieldValue("student", null);
      formik.setFieldValue("points", null);
      formik.setFieldValue("description", "");

      handleClose(pointsEntry);

      setSubmitting(false);

      handleOpenAlert("Points Added");

      console.log(studentRef.current);
      studentRef.current.focus();
    },
  });

  const dialogTitle = existingPointsEntry ? "Edit Points" : "Add Points";
  const submitButtonText = existingPointsEntry ? "Edit" : "Add";

  const pointsOptions = [];
  const minPoints = -10;
  const maxPoints = 10;
  for (let i = minPoints; i <= maxPoints; i++) {
    if (i !== 0) {
      pointsOptions.push(i);
    }
  }

  const handleCourseChange = event => {
    const course = event.target.value;
    if (course) {
      const students = course.studentIds.map(studentId => studentMap[studentId]);
      setStudents(students);
    } else {
      setStudents([]);
    }
    formik.values.student = null;
    formik.handleChange(event);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen={fullScreen}
      disableEscapeKeyDown
      fullWidth
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <PPAutocomplete
            autoFocus
            id="course"
            options={courses}
            getOptionLabel={course => course.name}
            label="Course"
            value={formik.values.course}
            onChange={handleCourseChange}
            error={formik.touched.course && Boolean(formik.errors.course)}
            helperText={formik.touched.course && formik.errors.course}
          />
          <PPAutocomplete
            id="student"
            ref={studentRef}
            options={students}
            getOptionLabel={student => student.name}
            label="Student"
            value={formik.values.student}
            onChange={formik.handleChange}
            error={formik.touched.student && Boolean(formik.errors.student)}
            helperText={formik.touched.student && formik.errors.student}
            renderOption={(props, student) => {
              return (
                <li {...props} key={student.studentId}>
                  <Grid container alignItems="center">
                    <Grid item>
                      {student.photoUrl ? (
                        <Box
                          component="img"
                          sx={{
                            display: "block",
                            height: "40px",
                            backgroundColor: "#F0F0F0",
                            mr: 2
                          }}
                          alt="Profile photo"
                          src={student.photoUrl}
                          referrerPolicy="no-referrer"
                        />
                      ) : (
                        <Box
                          sx={{
                            height: "40px",
                            width: "40px",
                            backgroundColor: "#F0F0F0",
                            mr: 2
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      <span>{student.name}</span>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
          <PPAutocomplete
            // freeSolo
            id="points"
            options={pointsOptions}
            getOptionLabel={points => points > 0 ? "+" + points.toString() : points.toString()}
            label="Points"
            value={formik.values.points}
            onChange={formik.handleChange}
            error={formik.touched.points && Boolean(formik.errors.points)}
            helperText={formik.touched.points && formik.errors.points}
          />
          <TextField
            id="description"
            margin="normal"
            label="Description"
            fullWidth
            multiline
            minRows={8}
            maxRows={16}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description ? formik.errors.description : "NOTE: The student can see the notes entered here."}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} disabled={formik.isSubmitting} tabIndex={-1}>Cancel</Button>
          <Button type="submit" disabled={formik.isSubmitting}>{submitButtonText}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPointsDialog;