import React from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const HomeStudent = ({ user, courseMap, teacherMap, pointsEntries }) => {
  let totalPoints = 0;

  const startDate = new Date("2022-09-06T00:00:00Z");
  const endDate = new Date("2022-10-20T00:00:00Z");
  const now = new Date();
  const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  const numberOfClasses = [0, 8, 4, 3, 4, 4, 0]; // Sunday - Saturday, maps to getDay()

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const day = d.getUTCDay();

    if (d.getTime() <= today.getTime()) {
      let points = numberOfClasses[day] * 3;

      pointsEntries.forEach(pointsEntry => {
        const pointsEntryDate = new Date(pointsEntry.dateCreated);
        const pointsEntryDateUTC = new Date(Date.UTC(pointsEntryDate.getFullYear(), pointsEntryDate.getMonth(), pointsEntryDate.getDate()));
        if (pointsEntryDateUTC.getTime() == d.getTime()) {
          points += pointsEntry.points;
        }
      });

      totalPoints += points;
    }
  }

  const columns = [
    {
      field: "date",
      headerName: "Date",
      type: "dateTime",
      width: 140,
      valueFormatter: params => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "teacher",
      headerName: "Teacher",
      width: 180,
    },
    {
      field: "course",
      headerName: "Course",
      width: 160,
    },
    {
      field: "points",
      headerName: "Points",
      type: "number",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 180,
      sortable: false
    }
  ];

  const rows = pointsEntries.map(pointsEntry => {
    return {
      date: new Date(pointsEntry.dateCreated).toLocaleDateString(),
      teacher: teacherMap[pointsEntry.teacherId].name,
      course: courseMap[pointsEntry.courseId].name,
      points: pointsEntry.points,
      description: pointsEntry.description
    };
  });

  return (
    <Box>
      <h1>Your points</h1>
      <p>Toal points earned so far: { totalPoints }</p>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map(column => (
                  <TableCell key={column.field}>{row[column.field]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HomeStudent;