import React from "react";
import { Box, Link } from "@mui/material";

const HomeUnknown = () => {
  return (
    <Box>
      <h1>No account found.</h1>
      <p>Sorry, you’re not recognized as either a teacher or a student.</p>
      <p>If you believe this is a mistake, please <Link href="mailto:rocky@ummtech.org">contact us</Link>.</p>
    </Box>
  );
};

export default HomeUnknown;