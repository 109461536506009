const getConfig1 = environment => ({
  awsRegion: "us-west-2",
  awsApiName: "api",
  twitterUsername: "dumptrumpsocial",
  twitterUrl: "https://twitter.com/dumptrumpsocial",
  facebookUrl: "https://www.facebook.com/dumptrumpsocial",
  instagramUrl: "https://www.instagram.com/dumptrumpsocial",
  shareText: "$username, will you add your favorite books and podcasts to $url? 🙏",
  shareTextFriend: "I am standing up to hate by holding Trump and his enablers accountable this November. Will you join me?",
  shareBaseUrl: "https://rec.page/",
  shareHashtag: "ShareYourRecs",
  electionDay: "2020-11-03",
  oldestReasonableAge: 150,
  experiment: {}
});

const getConfig2 = environment => {
  if (environment === "prod") {
    return {
      baseUrl: "partypoints.org",
      awsIdentityPoolId: "us-west-2:95a66fdd-35a4-425e-adc7-3e22cf1f91ea",
      awsUserPoolId: "us-west-2_RIQbcqq7A",
      awsUserPoolWebClientId: "3emd6mcrdhc9pftgmmciotepu7",
      awsApiEndpoint: "https://api.partypoints.org",
      facebookPixelId: "473186073290446",
      googleTrackingId: "UA-157507513-3",
    };
  } else {
    return {
      baseUrl: "dev.partypoints.org",
      awsIdentityPoolId: "us-west-2:3e14e038-6314-4514-b808-384ff8fbebb5",
      awsUserPoolId: "us-west-2_QFJU75MfR",
      awsUserPoolWebClientId: "4qiqja032s6h9g994j7npvr286",
      awsApiEndpoint: "https://api-dev.partypoints.org",
      facebookPixelId: "2909439315770087",
      googleTrackingId: "UA-157507513-2",
    };
  }
};

const getConfig3 = environment => {
  if (environment === "local") {
    return {
      isRegisteredApiEnabled: true,
      isRegisteredApiResponse: true,
      verifyAddressApiEnabled: true,
    };
  } else {
    return {
      isRegisteredApiEnabled: true,
      isRegisteredApiResponse: false,
      verifyAddressApiEnabled: true,
    };
  }
};

export default {
  ...getConfig1(process.env.REACT_APP_ENVIRONMENT),
  ...getConfig2(process.env.REACT_APP_ENVIRONMENT),
  ...getConfig3(process.env.REACT_APP_ENVIRONMENT),
};