import React, { useState } from "react";
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { api, auth } from "../core";

const Header = ({ user, handleOpenAddPointsDialog }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleRefreshData = async () => {
    await api.refreshData();
    handleCloseUserMenu();
  };

  const handleSignOut = async () => {
    await auth.logout();
    handleCloseUserMenu();
  };

  const pages = [
    { id: "", name: "Overview" },
    { id: "students", name: "Students" },
    { id: "points", name: "Points" },
    // { id: "insights", name: "Insights" },
    // { id: "admin", name: "Admin" },
  ];

  const settings = [
    { name: "Refresh Data", callback: handleRefreshData },
    { name: "Sign Out", callback: handleSignOut }
  ];

  const useRouteMatch = patterns => {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  };

  // https://mui.com/material-ui/guides/routing/#TabsRouter.js
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  const routeMatch = useRouteMatch(["/", "/students", "/points"]);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Toolbar disableGutters sx={{ justifyContent: "right" }}>
      {user.isTeacher &&
        <>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
            <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", sm: "none" },
              }}
            >
              {pages.map(({id, name}) => (
                <MenuItem
                  key={id}
                  to={"/" + id}
                  component={RouterLink}
                  onClick={handleCloseNavMenu}
                >
                  {name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            <Tabs value={currentTab}>
              {pages.map(({id, name}) => (
                <Tab
                  key={id}
                  label={name}
                  value={"/" + id}
                  to={"/" + id}
                  component={RouterLink}
                />
              ))}
            </Tabs>
          </Box>
          <Button startIcon={<AddIcon />} onClick={() => handleOpenAddPointsDialog()} sx={{mr: 4}}>Add Points</Button>
        </>
      }
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={user.name} src={user.photoUrl} imgProps={{ referrerPolicy: "no-referrer" }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map(({name, callback}) => (
            <MenuItem key={name} onClick={callback}>{name}</MenuItem>
          ))}
        </Menu>
      </Box>
    </Toolbar>
  );
};

export default Header;