import React from "react";
import { Logger } from "@aws-amplify/core";
// import bugsnag from "@bugsnag/js";
// import bugsnagReact from "@bugsnag/plugin-react";

const log = {};

// const bugsnagClient = bugsnag({
//   apiKey: "b4e3529b07d7bcf967a30dfedf7cd911",
//   appVersion: process.env.REACT_APP_VERSION,
//   releaseStage: process.env.REACT_APP_ENVIRONMENT,
//   notifyReleaseStages: ["prod"],
// });

// bugsnagClient.use(bugsnagReact, React);
// log.ErrorBoundary = bugsnagClient.getPlugin("react");

// window.LOG_LEVEL = "DEBUG"; // ERROR, WARN, INFO, DEBUG, VERBOSE
const logger = new Logger("Party Points", "VERBOSE");

// log.setUser = (userId, name) => {
//   bugsnagClient.user = {
//     id: userId,
//     name
//   };
// };

// log.leaveBreadcrumb = event => {
//   bugsnagClient.leaveBreadcrumb(event);
// };

log.debug = (message, data) => {
  logger.debug(message, data);
};

log.error = (message, error) => {
  logger.error(message, error);

  // if (error) {
  //   bugsnagClient.notify(error);
  // } else if (typeof message !== "string") {
  //   bugsnagClient.notify(message);
  // }
};

export default log;