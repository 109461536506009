import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import update from "immutability-helper";
import { api } from "../core";

const Points = ({ pointsEntry, user, courseMap, studentMap, teacherMap, pointsEntries, setPointsEntries, handleOpenAddPointsDialog, handleOpenAlert }) => {
  const pointsEntryMap = pointsEntries.reduce((memo, pointsEntry) => {
    memo[pointsEntry.pointsEntryId] = pointsEntry;
    return memo;
  }, {});

  const columns = [
    {
      field: "date",
      headerName: "Date",
      type: "dateTime",
      width: 140,
      valueFormatter: params => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "teacher",
      headerName: "Teacher",
      width: 180,
    },
    {
      field: "course",
      headerName: "Course",
      width: 160,
    },
    {
      field: "student",
      headerName: "Student",
      width: 140,
    },
    {
      field: "points",
      headerName: "Points",
      type: "number",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 180,
      sortable: false
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const pointsEntry = pointsEntryMap[id];
        if (pointsEntry.teacherId === user.userId) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleRemoveClick(id)}
              color="inherit"
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  const rows = pointsEntries.map(pointsEntry => {
    const id = pointsEntry.pointsEntryId;
    const date = pointsEntry.dateCreated;
    const teacher = pointsEntry.teacherId === user.userId ? user.name : teacherMap[pointsEntry.teacherId].name;
    const course = courseMap[pointsEntry.courseId].name;
    const student = studentMap[pointsEntry.studentId].name;
    const points = pointsEntry.points;
    const description = pointsEntry.description;
    return { id, date, teacher, course, student, points, description };
  });

  const handleEditClick = id => () => {
    const pointsEntry = pointsEntryMap[id];
    handleOpenAddPointsDialog(pointsEntry);
  };

  const handleRemoveClick = id => async () => {
    await api.removePointsEntry(id);
    handleOpenAlert("Points Removed");
    const index = pointsEntries.findIndex(pointsEntry => pointsEntry.pointsEntryId === id);
    removePointsEntries(index);
  };

  const removePointsEntries = useCallback(index => {
    setPointsEntries(prevPointsEntries =>
      update(prevPointsEntries, {
        $splice: [
          [index, 1]
        ]
      })
    );
  }, []);

  return (
    <Box sx={{ pt: 2 }}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
};

export default Points;