import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Box, Container, Snackbar } from "@mui/material";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";
import AddPointsDialog from "./AddPointsDialog";
import Header from "./Header";
import Home from "./Home";
import HomeStudent from "./HomeStudent";
import HomeUnknown from "./HomeUnknown";
import Overview from "./Overview";
import Points from "./Points";
import Students from "./Students";
import { analytics, api, auth, cache, log, Loader } from "../core";

const App = ({ isAuthenticated }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [courseMap, setCourseMap] = useState(null);
  const [studentMap, setStudentMap] = useState(null);
  const [teacherMap, setTeacherMap] = useState(null);
  const [pointsEntries, setPointsEntries] = useState(null);
  const [existingPointsEntry, setExistingPointsEntry] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // if (isAuthenticated === false && (user || courses || students)) {
  //   setUser(null);
  //   setCourses(null);
  //   setStudents(null);
  // }

  useEffect(() => {
    (async () => {
      log.debug("Initializing...");

      const code = searchParams.get("code");
      setSearchParams({});
      if (code) {
        const result = await api.getToken(code);
        if (result) {
          if (await auth.login(result.identityId, result.token)) {
            cache.set({ userId: result.user.userId }, cache.STORAGE);
            setUser(result.user);
            if (result.user.isTeacher || result.user.isStudent) {
              const courseMap = result.courses.reduce((memo, course) => {
                memo[course.courseId] = course;
                return memo;
              }, {});
              setCourseMap(courseMap);
              if (result.user.isTeacher) {
                const studentMap = result.students.reduce((memo, student) => {
                  memo[student.studentId] = student;
                  return memo;
                }, {});
                setStudentMap(studentMap);
              }
              const teacherMap = result.teachers.reduce((memo, teacher) => {
                memo[teacher.userId] = teacher;
                return memo;
              }, {});
              setTeacherMap(teacherMap);
              setPointsEntries(result.pointsEntries);
            }
            log.debug("Login success!");
            // navigate("/students");
          } else {
            log.error("Failed to login.");
          }
        } else {
          log.error("Failed to get token.");
        }
      } else {
        const isAuthenticated = await auth.fetchIsAuthenticated();
        if (isAuthenticated) {
          const result = await api.getData();
          setUser(result.user);
          if (result.user.isTeacher || result.user.isStudent) {
            const courseMap = result.courses.reduce((memo, course) => {
              memo[course.courseId] = course;
              return memo;
            }, {});
            setCourseMap(courseMap);
            if (result.user.isTeacher) {
              const studentMap = result.students.reduce((memo, student) => {
                memo[student.studentId] = student;
                return memo;
              }, {});
              setStudentMap(studentMap);
            }
            const teacherMap = result.teachers.reduce((memo, teacher) => {
              memo[teacher.userId] = teacher;
              return memo;
            }, {});
            setTeacherMap(teacherMap);
            setPointsEntries(result.pointsEntries);
          }
        }
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setUser(null);
      setCourseMap(null);
      setStudentMap(null);
      setTeacherMap(null);
      setPointsEntries(null);
    }
  }, [isAuthenticated]);

  const location = useLocation();

  const getPage = () => {
    if (!user) {
      return <Home />;
    } else if (user.isTeacher) {
      if (location.pathname === "/") {
        return <Overview user={user} />;
      } else if (location.pathname === "/students") {
        return <Students user={user} courseMap={courseMap} studentMap={studentMap} pointsEntries={pointsEntries} />;
      } else if (location.pathname === "/points") {
        return <Points pointsEntry={null} user={user} courseMap={courseMap} studentMap={studentMap} teacherMap={teacherMap} pointsEntries={pointsEntries} setPointsEntries={setPointsEntries} handleOpenAddPointsDialog={handleOpenAddPointsDialog} handleOpenAlert={handleOpenAlert} />;
      } else {
        return <p>BAD PAGE</p>;
      }
    } else if (user.isStudent) {
      return <HomeStudent user={user} courseMap={courseMap} teacherMap={teacherMap} pointsEntries={pointsEntries} />;
    } else {
      return <HomeUnknown />;
    }
  };

  const handleOpenAddPointsDialog = pointsEntry => {
    setExistingPointsEntry(pointsEntry);
    setDialogOpen(true);
  };

  const handleCloseAddPointsDialog = pointsEntry => {
    if (pointsEntry) {
      const index = existingPointsEntry ? pointsEntries.findIndex(pointsEntry => existingPointsEntry.pointsEntryId === pointsEntry.pointsEntryId) : null;
      addPointsEntries(pointsEntry, index);
    } else {
      setDialogOpen(false);
    }
  };

  const handleOpenAlert = message => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const addPointsEntries = useCallback((pointsEntry, index) => {
    if (index === null) {
      setPointsEntries(prevPointsEntries =>
        update(prevPointsEntries, {
          $push: [pointsEntry]
        })
      );
    } else {
      setPointsEntries(prevPointsEntries =>
        update(prevPointsEntries, {
          [index]: {
            $set: pointsEntry
          }
        })
      );
    }
  }, []);

  // TouchBackend has some known issues with mobile Safari:
  // https://github.com/react-dnd/react-dnd/issues/2206
  // Solution might be to use a CustomDragLayer:
  // https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer
  // Although there's probably a way to do something similar to how the library handles the preview:
  // https://react-dnd.github.io/react-dnd/docs/api/drag-preview-image
  return (
    <DndProvider backend={HTML5Backend}>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="lg" sx={{ height: "100%", pl: 4, pr: 4, pb: 4 }}>
          {user &&
            <>
              <Header user={user} handleOpenAddPointsDialog={handleOpenAddPointsDialog} />
              {user.isTeacher &&
                <>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                  >
                    <Alert onClose={handleCloseAlert} severity="success" sx={{ width: "100%" }}>
                      {alertMessage}
                    </Alert>
                  </Snackbar>
                  <AddPointsDialog
                    existingPointsEntry={existingPointsEntry}
                    user={user}
                    courseMap={courseMap}
                    studentMap={studentMap}
                    open={dialogOpen}
                    handleClose={handleCloseAddPointsDialog}
                    handleOpenAlert={handleOpenAlert}
                  />
                </>
              }
            </>
          }
          { getPage() }
        </Container>
      )}
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.memory.auth.isAuthenticated
});

export default connect(mapStateToProps)(App);