import React from "react";
import { Box, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { api, util } from "../core";
import logo from "../assets/logo.svg";

const Home = () => {
  const handleSignIn = async () => {
    const result = await api.getAuthLink();
    if (result) {
      util.navigateTo(result.url);
    } else {
      throw new Error("Unable to get the auth link.");
    }
  };

  return (
    <Box sx={{ pt: 4, minHeight: "100%", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
      <Box sx={{ flexGrow: 1 }}>
        <img src={logo} alt="Logo" />
        <Box sx={{ pl: 1, pr: 1 }}>
          <p>Party Points is a tool for educators to reward students with points for showing up, engaging with their classwork, and respecting others. Students who earn enough points get to participate in a fun group activity! 🎉</p>
          <p>To get started, sign in below with your school email.</p>
          <Button onClick={handleSignIn} sx={{ ml: -1, mt: 1 }}>Sign in with Google</Button>
        </Box>
      </Box>
      <Box sx={{ flexShrink: 0, pl: 1, pr: 1 }}>
        <Button href="mailto:rocky@ummtech.org" sx={{ display: { xs: "block", sm: "inline-flex" }, ml: -1, mt: 1, mr: 4 }}>Contact</Button>
        <Button href="/privacy" sx={{ display: { xs: "block", sm: "inline-flex" }, ml: -1, mt: 1, mr: 4 }}>Privacy Policy</Button>
        <Box sx={{ display: { xs: "block", sm: "inline-flex" }, verticalAlign: "middle", color: "#666", fontSize: "0.875rem", lineHeight: "1.75", letterSpacing: "0.02857em", mt: 1, padding: "6px 0"}}>© 2022 UMMTECH LLC</Box>
      </Box>
    </Box>
  );
};

export default Home;